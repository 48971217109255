/*
 * @Author: diaowangtao
 * @Date: 2020-08-18 17:19:10
 * @Description: 埋点点击事件
 */

export default {
  click_news: {
    type: 'click',
    evtid: 'international_PC_click_news',
    topic: '点击文章链接',
    info: ''
  },
  
}
