// import axiosHttp from '@common/js/ajax/axiosHttp.js'
// 此埋点文件只适用于嵌入app内的页面
import mdPathList from './path/hrPathList'
import mdClickList from './path/hrClickList'
import mdInfo from './path/info'
// import splitUrl from '@/utils/splitUrl'



export default function() {

   // 调用埋点
   var mdObj = {
     pf: 6, // pf参数，表示当前项目，必传
     type: 'pc', // pc/app/wap，必传
     // 页面路径对应id配置，用于页面进入离开的埋点请求，必传
     mdPathList: mdPathList,
     // 点击事件对应id配置，必传
      mdClickList: mdClickList,
      mdInfo:mdInfo

   }
   if (window._htmd) {
     console.log('给埋点传参数:', mdObj)
     window._htmd(mdObj)
   }
}