<!--
 * @Author: yangjinlai
 * @Date: 20220328
 * @Description: 浮窗组件
-->
<template>
  <div class="floatWindow">
    <!-- 线上开户 -->
    <router-link to="online" class="goWindow goOnLine" @click.native="changePage(7)">
      <img class="icon online" :src="require('@/assets/img/online.png')"></img>
      <div class="tip">线上开户</div>
    </router-link>

    <!-- 联系我们 -->
    <router-link to="contact-us"  class="goWindow goContact" @click.native="changePage(4)">
      <img class="icon" :src="require('@/assets/img/phone.png')"></img>
      <div class="tip">恒天国际客服电话 852-37027888</div>
    </router-link>

  </div>
</template>

<script>
export default {
  name: 'myHead',
  data() {
    return {
      language: 0, //0简体，1繁体
    }
  },
  props: {},
  methods: {
    changePage(i) {
      this.$emit('changePage', i)
      //页面滚动到顶部
      document.documentElement.scrollTop = 0; 
      document.body.scrollTop = 0; 
		},
  },
}
</script>

<style lang="scss">
.floatWindow{
  
  position: fixed;
  right: 60px;
  bottom: 100px;

  .goWindow{
    width: 40px;
    height: 40px;
    // line-height: 40px;
    text-align: center;
    margin-bottom: 10px;
    background: #DAB678;
    border-radius: 4px;
    display: block;
    position: relative;
    
    &:last-child{
      margin-bottom: 0;
    }

    &:hover{
      background: #C3A165;

      .tip{
        display: block;
      }
    }
    
    .icon{
      margin-top: 8px;
      &.online{
        margin-top: 11px;
      }
    }

    .tip{
      display: none;
      padding: 12px 22px 12px 16px;
      font-size: 16px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 16px;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      right: 50px;
      white-space: nowrap;
      top: 0;
      border-radius: 10px;

      &:after{
        content: '';
        display: block;
        position: absolute;
        right: -6px;
        top: 15px;
        background: url('~@/assets/img/arrow_2.png') no-repeat center;
        background-size: 100%;
        width: 6px;
        height: 10px;
      }
    }
  }
}
</style>