/*
 * @Author: diaowangtao
 * @Date: 2020-08-18 17:19:10
 * @Description: 
 */
/**
 * 路由和evtList对应表
 *
 * 国际资本项目使用
 *
 * 每条evtid都以 international_ 开始，表示是属于国际资本的
 */
export default {
  '/index': {
    evtid: 'international_PC_home',
    topic: '国际资本PC-首页',
    info: ''
  },
  '/company': {
    evtid: 'international_PC_company',
    topic: '国际资本PC-公司介绍',
    info: ''
  },
  '/invest': {
    evtid: 'international_PC_investmentStrategy',
    topic: '国际资本PC-投资策略',
    info: ''
  },
  '/news': {
    evtid: 'international_PC_articleDetail',
    topic: '国际资本PC-文章详情',
    info: ''
  },
  '/join-us': {
    evtid: 'international_PC_joinUs',
    topic: '国际资本PC-加入我们',
    info: ''
  },
  '/online': {
    evtid: 'international_PC_online',
    topic: '国际资本PC-线上开户',
    info: ''
  },
  '/job': {
    evtid: 'international_PC_postDetail',
    topic: '国际资本PC-职位详情',
    info: ''
  },
  '/contact-us': {
    evtid: 'international_PC_contactUs',
    topic: '国际资本PC-联系我们',
    info: ''
  },
  '/privacy': {
    evtid: 'international_PC_copyRight',
    topic: '国际资本PC-版权声明',
    info: ''
  },
  '/disclaimer': {
    evtid: 'international_PC_disclaimer',
    topic: '国际资本PC-免责声明',
    info: ''
  }
}
