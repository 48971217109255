/*
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 
 */
import Vue from 'vue'
import Router from 'vue-router'

const index = r => require.ensure([], () => r(require('@/pages/home/index.vue')), 'chunkname1');
const company = r => require.ensure([], () => r(require('@/pages/company/index.vue')), 'chunkname2');
const invest = r => require.ensure([], () => r(require('@/pages/invest/index.vue')), 'chunkname3');
const news = r => require.ensure([], () => r(require('@/pages/invest/news.vue')), 'chunkname4');
const newsPdf = r => require.ensure([], () => r(require('@/pages/invest/news-pdf.vue')), 'chunkname5');
const join = r => require.ensure([], () => r(require('@/pages/join/index.vue')), 'chunkname6');
const job = r => require.ensure([], () => r(require('@/pages/join/job.vue')), 'chunkname7');
const privacy = r => require.ensure([], () => r(require('@/pages/privacy/index.vue')), 'chunkname8');
const disclaimer = r => require.ensure([], () => r(require('@/pages/disclaimer/index.vue')), 'chunkname9');
const contact = r => require.ensure([], () => r(require('@/pages/contact/index.vue')), 'chunkname10');
const online = r => require.ensure([], () => r(require('@/pages/online/index.vue')), 'chunkname11');
const download = r => require.ensure([], () => r(require('@/pages/download/index.vue')), 'chunkname12');

Vue.use(Router)
const router = new Router({
  mode: '',
  routes: [
    {path: '/', redirect: '/index'},
    {
      path: '/index',
      name: 'index',
      component: index
    },
    {
      path: '/company',
      name: 'company',
      component: company
    },
    {
      path: '/invest',
      name: 'invest',
      component: invest
    },
    {
      path: '/news',
      name: 'news',
      component: news
    },
    {
      path: '/news-pdf',
      name: 'news-pdf',
      component: newsPdf
    },
    {
      path: '/join-us',
      name: 'join',
      component: join
    },
    {
      path: '/job',
      name: 'job',
      component: job
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: privacy
    },
    {
      path: '/disclaimer',
      name: 'disclaimer',
      component: disclaimer
    },
    {
      path: '/contact-us',
      name: 'contact',
      component: contact
    },
    {
      path: '/online',
      name: 'online',
      component: online
    },
    {
      path: '/download',
      name: 'download',
      component: download
    },
  ]
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  console.log( to )
  next();
});

export default router

