/*
 * @Author: diaowangtao
 * @Date: 2020-08-28 14:37:35
 * @Description: 埋点返回点击文章的id
 */

var mdEvtInfo = function (href, type, htmdEvt, eTarget) {
  var h5_info
  //点击时
  if (type == "click") {
    // if (href.indexOf('/index') != -1) {
    //   h5_info = '首页点击文章，id为' + eTarget.id;
    // } else if (href.indexOf('/invest') != -1) {
    //   h5_info = '投资策略页点击文章，id为' + eTarget.id;
    // } else if (href.indexOf('/join-us') != -1) {
    //   h5_info = '加入我们页点击文章，id为' + eTarget.id;
    // } else {
    //   h5_info = '点击文章，id为' + eTarget.id;
    // }
    h5_info = '点击文章，id为' + eTarget.id;
    console.log(h5_info)
  }
  h5_info = (h5_info ? h5_info : '');
  return h5_info;
}

module.exports = mdEvtInfo;
