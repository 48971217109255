/*
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 
 */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import "babel-polyfill"
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import xss from 'xss'

Vue.config.productionTip = false

import './assets/css/base.scss';
import 'element-ui/lib/theme-chalk/index.css'


// 绑定日期转化插件
import dayjs from 'dayjs'
Vue.prototype.$dayjs = dayjs

//绑定公共anchor方法
import anchorjs from './utils/anchor'
Vue.prototype.$anchorjs = anchorjs

import mapjs from './utils/map'
Vue.prototype.$mapjs = mapjs

import i18n from './lang'
import changeLang from './utils/changeLang'
Vue.prototype.$lang = changeLang
Vue.config.productionTip = false
Vue.filter('sLang', function(value) {
  return changeLang(value, store.state.language)
})


import {
  Carousel,
  CarouselItem,
  Pagination,
  Select,
  Option,
  Step,Steps
 
} from 'element-ui';

Vue.use(Carousel).use(CarouselItem).use(Pagination).use(Select).use(Option).use(Step).use(Steps)

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'Ir969VRnTy9Ca2pfbDsv4UcCF8L9cMGL'
})

import hrMd from './utils/md/hrMd'
hrMd();

/* eslint-disable no-new */

let myVue = new Vue({
  el: '#app',
  router,
  store,
  i18n,
  xss,
  components: {
    App
  },
  template: '<App/>'
})

export default myVue
