<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 尾部组件
-->
<template>
  <div class="footer_out">
    <div class="footer w1200">
      <router-link to="index"  @click.native="changePage(0)"><img :src="require('@/assets/img/logo_white.png')" alt /></router-link>
      <router-link to="join-us" @click.native="changePage(3)" class="section_2">{{$t('header.joinUs')}}</router-link>
      <router-link to="contact-us" @click.native="changePage(4)" class="section_2">{{$t('header.contactUs')}}</router-link>
      <router-link to="privacy" @click.native="changePage(5)" class="section_2">{{$t('header.copyRight')}}</router-link>
      <router-link to="disclaimer" @click.native="changePage(6)" class="section_2">{{$t('header.disclaimer')}}</router-link>
      <div>
        <p>{{$t('footer.copyRight_l')}}</p>
        <p>{{$t('footer.copyRight_c')}}</p>
        <!-- <p>{{$t('footer.copyRight_r')}}</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myFoot',
  methods: {
    changePage(i) {
      this.$emit('changePage', i)
    },
  },
}
</script>

<style lang='scss'>
.footer_out {
  width: 100%;
  background: #0f1724;
  text-align: center;
  .footer {
    height: 286px;
    overflow: hidden;
    font-size: 13px;
    color: #ffffff;
    img {
      display: block;
      width: 298px;
      height: 50px;
      margin: 65px auto 0 auto;
    }
    .section_2 {
      display: inline-block;
      margin: 55px 25px 0 25px;
      cursor: pointer;
      color: #fff;
      opacity: .3;
    }
    a:hover {
      color: #dab678;
      opacity: 1;
    }
    p {
      display: inline-block;
      opacity: 0.5;
      margin: 55px 50px 0 50px;
    }
  }
}
</style>