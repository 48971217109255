<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 头部组件
-->
<template>
  <div class="nav">
    <!-- 黑底nav -->
    <div class="nav_top_out">
      <div class="nav_top w1200">
        <span class="left font10">{{$t('header.message')}}</span>
        <div class="right language font10">
          <span
            class="language_l"
            :class="[$store.state.language=='zh'?'language_active':'hand']"
            @click="changeLang('zh')"
          >简体</span>
          <span
            class="language_r"
            :class="[$store.state.language=='tc'?'language_active':'hand']"
            @click="changeLang('tc')"
          >繁体</span>
        </div>
        <p class="right font10">{{$t('header.phone')}}</p>
      </div>
    </div>
    <!-- 白底nav -->
    <div class="nav_bottom_out">
      <div class="nav_bottom w1200">

				<div class="navDiv">
					<router-link to="index" class="nav" @click.native="changePage(0)"><img :src="require('@/assets/img/logo_black.png')" class="left hand"/></router-link>
				</div>

				<div class="navDiv">
        	<router-link to="index" :class="[$store.state.page==0?'nav nav_active':'nav']" @click.native="changePage(0)">{{$t('header.home')}}</router-link>
					<!-- 二级菜单 -->
					<div class="secondNav">
						<span v-for="(item,index) in $t('secondHeader.home')"  @click="jumpAnchor('index', 0, index)">{{item}}</span>
						<!-- <span class='subNav' @click="jumpAnchor('index', 0, 1)">{{$t('secondHeader.home[1]')}}</span> -->
					</div>
				</div>

				<div class="navDiv">
					<router-link to="company" :class="[$store.state.page==1?'nav  nav_active':'nav']" @click.native="changePage(1)">{{$t('header.company')}}</router-link>
					<!-- 二级菜单 -->
					<div class="secondNav">
						<span v-for="(item,index) in $t('secondHeader.company')"  @click="jumpAnchor('company', 1, index)">{{item}}</span>
					</div>
				</div>
				
        <div class="navDiv">
					<router-link to="invest" :class="[$store.state.page==2?'nav  nav_active':'nav']" @click.native="changePage(2)">{{$t('header.investmentStrategy')}}</router-link>
					<!-- 二级菜单 -->
					<div class="secondNav">
						<span v-for="(item,index) in $t('secondHeader.investmentStrategy')"  @click="jumpAnchor('invest', 2, index)">{{item}}</span>
					</div>
				</div>

				<div class="navDiv">
					 <router-link to="online" :class="[$store.state.page==7?'nav  nav_active':'nav']" @click.native="changePage(7)">{{$t('header.online')}}</router-link>
				</div>

				<div class="navDiv">
					<router-link to="join-us" :class="[$store.state.page==3?'nav  nav_active':'nav']" @click.native="changePage(3)">{{$t('header.joinUs')}}</router-link>
				</div>

				<div class="navDiv">
					<router-link to="contact-us" :class="[$store.state.page==4?'nav  nav_active':'nav']" @click.native="changePage(4)">{{$t('header.contactUs')}}</router-link>
				</div>
        
        <div class="navDiv downApp">
					<router-link to="download" class="applogo" :class="[$store.state.page==15?'nav  nav_active':'nav']" @click.native="changePage(15)">{{$t('header.download')}}</router-link>
				</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'myHead',
  data() {
    return {
      language: 0, //0简体，1繁体
    }
  },
  props: {},
  methods: {
    changeLang(i) {
        this.$i18n.locale = i
        this.$store.commit('changeLanguage', i)

        //如果是联系我们页面，修改地图简体繁体
        if( this.$route.path == '/contact-us' ){
          this.$mapjs();
        }
        
    },
    changePage(i) {
			this.$emit('changePage', i)
		},

		//path--要去的页面路由
		//navIndex--二级导航所属的一级导航的索引
		//i--二级导航的索引
		jumpAnchor( path, navIndex, i){
			
			if( this.$route.path != '/'+path ){
				//不是当前页面
				this.$emit('changePage', navIndex)
				this.$router.push({ path: path, query:{ anchor: i }})
				//锚点定位需要在对应页面中获取参数后再调用this.$anchorjs方法
			}
			else{
        //已在当前页面
        if( this.$route.path == '/index' ){
          //首页
          let anchor = document.getElementById('anchor_'+i);
          if( !!anchor){
              let go = anchor.offsetTop;
              document.documentElement.scrollTop = go; 
              document.body.scrollTop = go; 
          }
        }
				else{
          //其他页面（关于我们，投资策略），触发菜单点击事件
          document.querySelectorAll('.menu span')[i].click();
        }
			}
		}
  },
}
</script>

<style lang="scss">
.nav {
  .nav_top_out {
    width: 100%;
    background: #0b121c;
    .nav_top {
      height: 34px;
      line-height: 34px;
      color: #f2f3f5;
      overflow: hidden;
      padding: 0 20px;
      .language {
        margin-left: 30px;
        // width: 80px;
        text-align: center;
        transform:translateY(-50%) scale(0.83, 0.83);
        position: relative;
        top: 50%;
        span {
          float: left;
          // width: 50%;
          width: 40px;
          box-sizing: content-box;
          height: 22px;
          line-height: 21px;
        }
        .language_l {
          // padding: 3px 8px 3px 12px;
          border: 1px solid #fff;
          border-radius: 100px 0 0 100px;
        }
        .language_r {
          // padding: 3px 12px 3px 8px;
          border: 1px solid #fff;
          border-radius: 0 100px 100px 0;
        }
        .language_active {
          background: #fff;
          color: #0b121c;
        }
      }
    }
  }
  .nav_bottom_out {
    .nav_bottom {
      height: 90px;
      line-height: 85px;
      padding: 0 35px;
      img {
        width: 298px;
        height: 50px;
      }
      .navDiv{
        font-size: 18px;
				color: #232d44;
				margin-left: 45px;
				cursor: pointer;
				display: inline-block;
				position: relative;
				line-height: 23px;
				vertical-align: middle;

				&:first-child{
					padding-bottom: 0;
					margin-left: 0;
					margin-right: 50px;
				}
    }
      .navDiv:hover {
        color: #dab678;

				.secondNav{
					display: block;
				}
      }
      .nav_active {
        color: #dab678;
        border-bottom: 2px #dab678 solid;
      }
      // 下载app
      .downApp img{
        width: 9px;
        height: 14px;
        background: url('../../assets/img/downphone.png') no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .applogo {
        text-indent: 15px;
        display: inline-block;
      }
      .applogo::before {
        content: '';
        display: block;
        width: 12px;
        height: 18px;
        background: url('../../assets/img/downphone.png') no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        top: 2px;
        left: 0;
      }
      .applogo.nav_active::before {
        background: url('../../assets/img/downphone_act.png') no-repeat center;
        background-size: 100% 100%;
      }
    }
  }

  .secondNav{
	  display: none;
	  position: absolute;
	  width: 140px;
	  padding-top: 6px;
    padding-bottom: 6px;
	  background: #fff;
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.26);
		border-radius: 4px;
		    top: 25px;
				left: 0;

	  span{
		  height: 44px;
			line-height: 44px;
			display: block;
			text-indent: 20px;
	  	font-size: 16px;
			font-weight: 400;
			color: #333333;
			// line-height: 16px;

			&:hover{
				background: #DAB678;
			}
	  }
  }
}
</style>