<!--
 * @Author: diaowangtao
 * @Date: 2020-08-17 14:31:09
 * @Description: 入口
-->
<template>
  <div id="app">
    <div class="body" :style="{'min-height':myHeight}">
      <my-head @changePage="changePage"/>
      <router-view :key="key" />
    </div>
    <my-foot @changePage="changePage" />
    <my-error v-if="$store.state.error==1"></my-error>
    <my-float-window @changePage="changePage"/>
  </div>
</template>

<script>
import myHead from '@/components/myHead/index.vue'
import myFoot from '@/components/myFoot/index.vue'
import myError from '@/components/myError/index.vue'
import myFloatWindow from '@/components/myFloatWindow/index.vue'
export default {
  name: 'App',
  data() {
    return {
      myHeight: '',
    }
  },
  components: {
    myHead: myHead,
    myFoot: myFoot,
    myError: myError,
    myFloatWindow: myFloatWindow
  },
  created() {
    this.pageState()
    this.getHeight()
  },
  computed: {
    key() {
      return this.$route.name !== undefined
        ? this.$route.name + +new Date()
        : this.$route + +new Date()
    },
  },
  
  methods: {

    changePage(i) {
      this.$store.commit('changePage', i) 
      // switch (i) {
      //   case 0:
      //     this.$router.push({ path: 'index' })
      //     break
      //   case 1:
      //     this.$router.push({ path: 'company' })
      //     break
      //   case 2:
      //     this.$router.push({ path: 'invest' })
      //     break
      //   case 3:
      //     this.$router.push({ path: 'join-us' })
      //     break
      //   case 4:
      //     this.$router.push({ path: 'contact-us' })
      //     break
      //   case 5:
      //     this.$router.push({ path: 'privacy' })
      //     break
      //   case 6:
      //     this.$router.push({ path: 'disclaimer' })
      //     break
      // }
    },
    getHeight() {
      let height = document.body.offsetHeight - 286
      this.myHeight = height + 'px'
    },
    pageState() {
      //在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem('store')) {
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem('store'))
          )
        )
        this.$i18n.locale = this.$store.state.language
      }

      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      })
    },
  },
  watch: {
    '$store.state.error': function () {
      setTimeout(() => {
        this.$store.commit('changeError', 0)
      }, 1000)
    },

    $route(to, from) {
      // 离开页面的埋点
      window._submitMd(2, from.path)
      // 进入页面的埋点
      window._submitMd(1, to.path)
    },
  },
}
</script>

<style lang="scss">
.body {
  min-height: 100%;
}
</style>
